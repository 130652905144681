import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TagsInput from 'react-tagsinput';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import 'react-tagsinput/react-tagsinput.css';
import '../App.css';
import { useSessionStore } from '../state/Store';
import LoadingButton from './LoadingButton';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function CreateQuestion() {
  const navigate = useNavigate();
  const { setError } = useSessionStore();
  const [creatorName, setCreatorName] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [tags, setTags] = useState([]);
  const [objective, setObjective] = useState("");
  const [question, setQuestion] = useState("");
  const createSession = useSessionStore((state) => state.createSession);
  const [success, setSuccess] = useState(false); // State to handle success Snackbar
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    // Simple email validation pattern
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!creatorEmail || !tags.length || !objective || !question || !creatorName) {
      setError("All fields must be filled out");
      setLoading(false);
      return;
    }

    if (!validateEmail(creatorEmail) || !tags.every(validateEmail)) {
      setError("Either your email address or the email address of one of your participants is invalid");
      setLoading(false);
      return;
    }

    const participants = tags.map((tag) => ({ email: tag }));
    try {
      const data = await createSession(question, objective, creatorEmail, creatorName, participants);

      if (data) {
        setSuccess(true); // Show success Snackbar
        setTimeout(() => {
          navigate(`/submit-answer?token=${data.token}`);
        }, 2000); // Redirect after 2 seconds
      }
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  const handleCreatorEmailChange = (e) => {
    setCreatorEmail(e.target.value);
  };

  const handleTagChange = (tags) => {
    setTags(tags);
  };

  return (
    <div className="moveToBottom">
      <Snackbar
        open={success}
        autoHideDuration={2000}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Successfully created the session!
        </Alert>
      </Snackbar>

      <h1>Create a question</h1>

      <span className="formInstructionText">Please enter your details below to ask your question. We promise complete confidentiality - no information shared here will be disclosed to others. </span>

      <div>
        <label htmlFor="creatorName">Name</label>
        <input id="creatorName" type="text" placeholder="Enter your first name" name="creatorName" required onChange={(e) => setCreatorName(e.target.value)} value={creatorName} />
      </div>

      <div>
        <label htmlFor="creatorEmail">Email</label>
        <input id="creatorEmail" type="email" placeholder="Enter your email address" name="creatorEmail" required onChange={handleCreatorEmailChange} value={creatorEmail} />
      </div>

      <div>
        <label htmlFor="objective">Outcome you want</label>
        <textarea id="objective" rows="4" name="objective" placeholder="Describe what information you want to gather from the responses. E.g., 'Find out who earns the most'" required onChange={(e) => setObjective(e.target.value)} value={objective}></textarea>
      </div>

      <div>
        <label htmlFor="question">Question for Participants</label>
        <textarea id="question" rows="4" name="question" placeholder="Write down the question that you want participants to answer secretly. E.g., 'How much do you earn?'" required onChange={(e) => setQuestion(e.target.value)} value={question}></textarea>
      </div>

      <div>
        <label htmlFor="text">Invite participants via email</label>
        <div className={tags.length > 0 ? 'has-tags' : 'has-no-tags'}>
        <TagsInput 
        id="text" 
        type="email" 
        value={tags} 
        onChange={handleTagChange} 
        addKeys={[9, 13, 188, 32]} 
        inputProps={{ placeholder: "Seperate with a comma or enter" }} 
        maxTags={100}
        />
        </div>
      </div>

      <LoadingButton loading={loading} onClick={handleSubmit} text="Create Question" />
      <span className="dentro-info dentro-info-extra-space">Confidant is powered by <a href="https://dentro-innovation.com" target="_blank" rel="noreferrer">Dentro</a></span>
    </div>
  );
}

export default CreateQuestion;
