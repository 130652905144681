import { create } from "zustand";

const backendUrl = process.env.REACT_APP_BACKEND_URL

const createSessionStore = (set, get) => ({
    objective: null,
    question: null,
    email: null,
    hasResponded: false,
    answer: null,
    isCreator: false,
    creatorName: null,
    token: null,
    otherParticipants: null,
    error: null,
    allParticipantsAnswered: false,
    setError: (error) => set({ error }),

    createSession: async (question, objective, creatorEmail, creatorName, participants) => {
        const response = await fetch(`${backendUrl}/create-session`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question, objective, creator: { email: creatorEmail, name: creatorName }, participants })
        });

        const data = await response.json();

        if (!response.ok) {
            set({ error: `Error creating session: ${data.error}` });
            return
        }

        set({
            objective: objective,
            question: question,
            email: creatorEmail,
            creatorName: creatorName,
            token: data.creator.token,
            otherParticipants: participants
        });

        return data;
    },

    fetchStatus: async (urlToken) => {
        let tokenValue = urlToken;
        if (tokenValue === "undefined" || typeof(tokenValue) === undefined || tokenValue === null || tokenValue === "") {
            tokenValue = get().token;
        }
        if (!tokenValue) {
            set({ error: `No auth token available` });
            return;
        }

        try {
            const response = await fetch(`${backendUrl}/status`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: tokenValue })
            });

            const data = await response.json();

            if (!response.ok) {
                set({ error: `Error fetching status of session. Token is probably invalid or Session is already terminated and deleted.\nError message: ${data.error}` });
                return
            }

            // Extract the main participant (the one with the token)
            const mainParticipant = data.participants.find(participant => participant.token === tokenValue);

            // Extract other participants (excluding the main participant)
            const otherParticipants = data.participants.filter(participant => participant.token !== tokenValue);

            // Find the creator among the participants
            const creator = data.participants.find(participant => participant.isCreator);

            set({
                objective: data.objective,
                question: data.question,
                email: mainParticipant.email,
                hasResponded: mainParticipant.hasResponded,
                answer: mainParticipant.answer,
                isCreator: mainParticipant.isCreator,
                creatorName: creator.name,
                token: mainParticipant.token,
                otherParticipants: otherParticipants
            });
        } catch (err) {
            set({ error: `Error fetching status: ${err}` });
        }
    },

    answerSession: async (urlToken, answer) => {
        let tokenValue = urlToken;
        if (tokenValue === "undefined" || typeof(tokenValue) === undefined || tokenValue === null || tokenValue === "") {
            tokenValue = get().token;
        }
        try {
            const response = await fetch(`${backendUrl}/answer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: tokenValue, answer })
            });

            const data = await response.json();

            if (!response.ok) {
                set({ error: `Error answering session: ${data.error}` });
                return
            }

            set({
                hasResponded: true,
                answer: answer,
                allParticipantsAnswered: data.allParticipantsAnswered
            });

            return data
        } catch (err) {
            set({ error: `Error answering session: ${err}` });
        }
    },
});

export const useSessionStore = create(createSessionStore);
