import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSessionStore } from '../state/Store';
import LoadingButton from './LoadingButton';
import '../App.css';

function SubmitAnswer() {
  const {
    objective,
    question,
    hasResponded,
    isCreator,
    answer,
    fetchStatus,
    answerSession
  } = useSessionStore();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  const [userAnswer, setUserAnswer] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!objective || !question) {
      fetchStatus(token);
    }
  }, [objective, question, token, fetchStatus]);

  const handleSubmitAnswer = async () => {
    setLoading(true);
    const response = await answerSession(token, userAnswer);

    if (response && response.allParticipantsAnswered) {
      // Handle successful response
      setResult(response.result);
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
    }

    setLoading(false);
  };

  // if (!objective || !question) {
  //   return <div>Loading...</div>; // Loading animation
  // }

  return (
    <div className="moveToBottom" style={{ color: 'white', textAlign: 'left' }}>
      <h1>{isCreator ? "Your question has been created" : "You have been invited to submit your answer"}</h1>
      <div className="questionToAnswer">
        <h2>Objective:</h2>
        <span>{objective}</span>
        <div className="questionToAnswerSpacing"></div>
        <h2>Question:</h2>
        <span>{question}</span>
      </div>
      {result ? (
        <>
          <h2>Your Result:</h2>
          <div>{result}</div>
        </>
      ) : hasResponded ? (
        <>
          <h2>Your Answer:</h2>
          <div>{userAnswer || answer}</div>
        </>
      ) : (
        <>
          <label htmlFor="textarea">Submit your answer</label>
          <textarea id="textarea" rows="5" name="textarea" required onChange={(e) => setUserAnswer(e.target.value)} style={{ color: 'black' }}></textarea>
        </>
      )}
      <LoadingButton loading={loading} onClick={handleSubmitAnswer} text="Submit Answer" disabled={hasResponded} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <span className="dentro-info dentro-info-extra-space">Confidant is powered by <a href="https://dentro-innovation.com" target="_blank" rel="noreferrer">Dentro</a></span>
    </div>
  );
}

export default SubmitAnswer;