import React from 'react';
import MUILoadingButton from '@mui/lab/LoadingButton';

const buttonStyle = {
  width: '100%',
  padding: '15px 20px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '.05rem',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  color: '#30C48D',
  background: '#fff',
  transition: 'opacity 0.2s ease-in-out',
  '&:hover': {
    opacity: 0.8,
    color: '#30C48D',
    background: '#fff',
  },
  '& .MuiCircularProgress-svg': {
    color: '#000000',
  },
};

function LoadingButton({ loading, onClick, text, disabled }) {
  return (
    <MUILoadingButton
      loading={loading}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      sx={buttonStyle}
    >
      {text}
    </MUILoadingButton>
  );
}

export default LoadingButton;
