import React from 'react';
import './App.css';
import logo from './logo_full.svg';
import { BrowserRouter as Router, Routes, Route, useNavigate, Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import CreateQuestion from './components/CreateQuestion';
import SubmitAnswer from './components/SubmitAnswer';
import { useSessionStore } from './state/Store';
import LoadingButton from './components/LoadingButton';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function HomePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleCreateQuestionClick = () => {
    setLoading(true);
    navigate("/create-question");
  };

  return (
    <div className="moveToBottom">
      <h1>Together we know, together we grow.</h1>
      <span>Discover shared knowledge without revealing secrets with Confidant, designed to verify common understanding among multiple participants while maintaining complete confidentiality.</span>
      <div class="appStartStepsIntro">
        <span>1. Create a question</span>
        <span>2. Invite participants</span>
        <span>3. Submit secret answers</span>
        <span>4. Receive AI-generated result</span>
      </div>
      <LoadingButton
        loading={loading}
        onClick={handleCreateQuestionClick}
        text="Create Question"
      />

      <span className="dentro-info">Confidant is powered by <a href="https://dentro-innovation.com" target="_blank" rel="noreferrer">Dentro</a></span>
    </div>
  );
}

function App() {
  const { error, setError } = useSessionStore();
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  return (
    <Router>
      <Snackbar
        open={!!error}
        autoHideDuration={8000}
        onClose={handleSnackBarClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackBarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <div className="App">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>


        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/create-question" element={<CreateQuestion />} />
          <Route path="/submit-answer" element={<SubmitAnswer />} />
        </Routes>


      </div>
    </Router>
  );
}

export default App;
